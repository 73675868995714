import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import FormMandatSepaVotreRIB from '../forms/MANDAT_SEPA/FormMandatSepaVotreRIB.component';
import FormMandatSepaTitulaireCompte from '../forms/MANDAT_SEPA/FormMandatSepaTitulaireCompte.component';
import FormMandatSepaVosCoordonneesBancaires from '../forms/MANDAT_SEPA/FormMandatSepaCoordonneesBancaires.component';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormMandatSepaImportDocuments from '../forms/MANDAT_SEPA/FormMandatSepaImportDocuments.component';
import FormMandatSepaSignature from '../forms/MANDAT_SEPA/FormMandatSepaSignature.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_COORDONNEES_BANCAIRES: {
    beforeTitle:
      'Cette opération est réservée aux adhérents Tutélaire. Elle s’effectue entièrement en ligne et nécessite une adresse e-mail active et un téléphone portable.',
    title: 'De quoi avez-vous besoin ?',
    description: 'De votre numéro d’adhérent, de votre RIB et d’un document d’identité en format dématérialisé.'
  },
  STEP_VOS_COORDONNEES: {
    beforeTitle:
      'Cette opération est réservée aux adhérents Tutélaire, déjà détenteurs d’une garantie. Elle s’effectue entièrement en ligne et nécessite une adresse e-mail active et un téléphone portable.',
    title: 'Où trouver mon numéro d’adhérent ?',
    hideTitleIcon: true,
    description:
      'Retrouvez votre Numéro d’adhérent en haut à gauche de votre espace adhérent, ou sur vos relevés de cotisation annuels.<br/><br/>Attention : il existe un numéro d’adhérent par contrat.',
    descriptionAsHTML: true
  },
  OTHER_STEPS: {
    beforeTitle:
      'Cette opération est réservée aux adhérents Tutélaire, déjà détenteurs d’une garantie. Elle s’effectue entièrement en ligne et nécessite une adresse e-mail active et un téléphone portable.'
  }
};

const mandatSepaConfig: FormConfigWithUrl = {
  formSteps: [
    {
      component: FormMandatSepaVosCoordonneesBancaires,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES_BANCAIRES} />
    },
    {
      component: FormMandatSepaTitulaireCompte,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_VOS_COORDONNEES} />
    },
    {
      component: FormMandatSepaVotreRIB,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.OTHER_STEPS} />
    },
    {
      component: FormMandatSepaImportDocuments,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.OTHER_STEPS} />
    },
    {
      component: FormMandatSepaSignature,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.OTHER_STEPS} />,
      data: { showCaptcha: true }
    }
  ]
};

[];

export default mandatSepaConfig;
