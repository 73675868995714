interface BaseMandatSepaProps {
  idFormulaire?: string;
}

interface Adresse {
  voie?: string;
  codePostal?: string;
  ville?: string;
}

interface ClientMandatSepaProps extends BaseMandatSepaProps {
  civilite?: string;
  prenom?: string;
  nom?: string;
  numerosAdherents?: string;
  telephone?: string;
  email?: string;
  adresse?: Adresse;
  infosAutresAdherents?: string;
}

interface DocumentMandatSepaProps extends BaseMandatSepaProps {
  document: FormData;
  numeroClient: string;
  categorie: string;
}

interface GetDocumentMandatSepaProps extends BaseMandatSepaProps {
  numeroClient: string;
}

interface GenerateMandatSepaProps extends GetDocumentMandatSepaProps {
  rib: {
    iban: string;
    bic: string;
  };
}

const API_URL = `${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/mandatSepa`;

async function callApi(endpoint: string, options = {}) {
  const response = await fetch(endpoint, options);
  return await response.json();
}

export const useUpsertClientMandatSepa = async (upsertClientProps: ClientMandatSepaProps): Promise<string> => {
  const options = {
    method: 'POST',
    body: JSON.stringify(upsertClientProps),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const res = await callApi(`${API_URL}/client`, options);
  return res.value;
};

export const useUpsertDocumentMandatSepa = async (upsertDocumentProps: DocumentMandatSepaProps): Promise<string> => {
  const endpoint = `${API_URL}/document/${upsertDocumentProps.idFormulaire}/${upsertDocumentProps.numeroClient}/${upsertDocumentProps.categorie}`;
  return callApi(endpoint, { method: 'POST', body: upsertDocumentProps.document });
};

export const useGetExistingDocumentMandatSepa = async (
  getDocumentProps: GetDocumentMandatSepaProps
): Promise<string> => {
  const endpoint = `${API_URL}/document/${getDocumentProps.idFormulaire}/${getDocumentProps.numeroClient}`;
  return callApi(endpoint);
};

export const useGenerateMandatSepa = async (generateMandatSepaProps: GenerateMandatSepaProps): Promise<Response> => {
  const endpoint = `${API_URL}/document/generateMandatSepa/${generateMandatSepaProps.idFormulaire}`;
  const options = {
    method: 'POST',
    body: JSON.stringify(generateMandatSepaProps),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(endpoint, options);
};

export const useGenerateProcedureSignature = async (
  generateProcureSignatureProps: GetDocumentMandatSepaProps
): Promise<string> => {
  const endpoint = `${API_URL}/signature/${generateProcureSignatureProps.idFormulaire}/${generateProcureSignatureProps.numeroClient}`;
  const res = await callApi(endpoint);
  return res.value;
};
