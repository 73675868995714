import FormPrecButton from 'components/FormPrecButton/FormPrecButton.component';
import { LeftPane } from 'components/LeftPane/LeftPane.component';
import RightPane from 'components/RightPane/RightPane.component';
import SubmitOverlay from 'components/SubmitOverlay/SubmitOverlay.component';
import UnavailableOverlay from 'components/UnavailableOverlay/UnavailableOverlay.component';
import useCallApi from 'hooks/useCallAPI.hook';
import _, { merge } from 'lodash';
import { Line } from 'rc-progress';
import React, { createRef, useEffect, useMemo, useRef, useState, VFC } from 'react';
import { useLocation } from 'react-router';
import {
  AccumulateStateToSerie,
  DistributeurReponse,
  FormParams,
  FormStepConfigWithNext,
  SeriesConfigs,
  SerieSubmitStatus,
  StepNode
} from 'types';
import {
  convertEmptyStringsToNull,
  createFormData,
  getTypeConsentement,
  getUrlParam,
  trimStringRecursively
} from 'utils';
import { getStepTree } from 'utils/FormStepConfigTree/FormStepConfigTree';
import { STEP_BY_personnas } from '../../configs/PROJET_MANUEL_DYNAMIQUE_CONFIG.config';
import { eDureesSejour, eDureesSejourFromPTF } from '../../forms/EXPATRIE/FormExpatVoyage.component';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import {
  addBesoinsPrevoyance,
  addNiveauxSanteParticulier,
  castToISODateRecursively,
  creerDateRappel,
  defaultAccumulateStateToSeries,
  getConfig,
  getLastFormStateInstance,
  getTitlePanelLeft,
  isReadOnly
} from './FormSerie.util';
import { ObfuscatedLink } from 'components/ObfuscatedLink/ObfuscatedLink.component';

interface FormSerieProps {
  distributeur: DistributeurReponse | undefined;
  formParams: FormParams;
  seriesConfigs: SeriesConfigs;
  urlFicheLegale: string;
  updateRequestFormParams?: (newFormParams: FormParams) => void;
}

type State = {
  activeNode?: StepNode;
  path: number[]; // array that contains the ids of forms that have been completed until now
  activeStep: number;
};

export type SubmitState = {
  status: SerieSubmitStatus;
  message?: string;
  stepCode?: string;
};

/**
 * Render a serie of interconnected forms
 */
const FormSerie: VFC<FormSerieProps> = ({
  distributeur,
  formParams,
  seriesConfigs,
  updateRequestFormParams,
  urlFicheLegale
}: FormSerieProps) => {
  const [rootNode, setRootNode] = useState<StepNode>();

  const [submitState, setSubmitState] = useState<SubmitState>({
    status: SerieSubmitStatus.PENDING
  });

  const [submitIntermediaryStepState, setSubmitIntermediaryStepState] = useState<SubmitState>({
    status: SerieSubmitStatus.PENDING
  });
  const previousStateRef = useRef<any>(null);
  const [state, setState] = useState<State>({
    activeNode: rootNode,
    path: [],
    activeStep: 0
  });

  const [captchaToken, setCaptchaToken] = useState<string>();

  const location = useLocation();

  const currentConfig = getConfig(formParams, seriesConfigs);

  const endPoint = currentConfig.endpoint;
  const breadCrumbConf = currentConfig.breadCrumbConf;
  const hideProgressBar = currentConfig.hideProgressBar;

  if (currentConfig.prefetch && currentConfig.prefetch.length > 0) {
    currentConfig.prefetch.map(fetch => fetch);
  }

  const avecRetour = currentConfig.avecRetour ?? true;
  const isDisplayMobileFooter = currentConfig.displayMobileFooter;

  const rightPaneRef = createRef<HTMLDivElement>();

  const isFormulaireProjetManuel = formParams.type === 'PROJET' && !seriesConfigs[formParams.cible];

  useEffect(() => {
    if (rightPaneRef?.current) {
      rightPaneRef.current.scrollTop = 0;
    }
  }, [state.activeStep]);

  const submitFormToApi = useCallApi<any, any>(
    (data: any) => {
      if (!endPoint) {
        return new Promise(resolve => resolve(new Response()));
      }

      const dataWithConsentement = {
        ...data,
        consentements: [...(data.consentements ?? []), getTypeConsentement(formParams.type, formParams.cible)].map(
          type => ({ type })
        )
      };

      setTimeout(() => {
        window.parent.postMessage('formWillBeSubmitted', '*');
      }, 200);

      return fetch(
        `${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/${getUrlParam(location.search, 'id')}/${endPoint}${
          state.activeNode?.config.formData ? '/formdata' : ''
        }`,
        {
          method: 'post',
          headers: state.activeNode?.config.formData ? {} : { 'Content-Type': 'application/json' },
          body: state.activeNode?.config.formData
            ? createFormData(dataWithConsentement)
            : JSON.stringify(dataWithConsentement)
        }
      );
    },
    (data, successCallback, formData) => {
      if (state.activeNode?.config.stepCode) {
        setSubmitIntermediaryStepState({
          status: SerieSubmitStatus.SUCCEEDED
        });
      } else {
        const dureeSejour = formData?.besoin?.dureeSejour || formData?.besoins?.dureeSejour;
        const redirectionUrl = formParams.parametres[`${keyParams}_REDIRECTION_URL`];
        const formIsEmbed = getUrlParam(location.search, 'embed');
        const hideConfirmation = getUrlParam(location.search, 'hideConfirmation') === 'true';
        if (formIsEmbed === 'true') {
          if (!hideConfirmation) {
            setSubmitState({ status: SerieSubmitStatus.SUCCEEDED });
          }
          if (dureeSejour) {
            window.parent.postMessage(
              {
                type: 'formHasSubmitted',
                moreThan12Months:
                  dureeSejour === eDureesSejour.SUP_12_MOIS || dureeSejour === eDureesSejourFromPTF.PLUS_12
              },
              '*'
            );
          } else {
            window.parent.postMessage({ type: 'formHasSubmitted' }, '*');
          }
        } else if (!_.isEmpty(redirectionUrl)) {
          window.location.assign(redirectionUrl);
        } else {
          setSubmitState({ status: SerieSubmitStatus.SUCCEEDED });
        }
      }
      successCallback && successCallback(data);
    },
    err => {
      state.activeNode?.config.stepCode
        ? setSubmitIntermediaryStepState({
            status: SerieSubmitStatus.FAILED,
            message: err.message
          })
        : setSubmitState({
            status: SerieSubmitStatus.FAILED,
            message: err.statusText
          });
    }
  );

  useEffect(() => {
    const configToUse: FormStepConfigWithNext[] = currentConfig.formSteps;

    if (isFormulaireProjetManuel) {
      // check if we have more than one typo personne ( TNS, particulier etc..)
      // If we have more than one , generate step
      // generate first step with next depend on typo
      let steps;
      if (formParams.personnas.length > 1) {
        steps = [...configToUse];
        if (steps[0] && steps[0].next) {
          steps[0].next.paths = formParams.personnas.reduce((acc: any, personna: string) => {
            acc[personna] = STEP_BY_personnas[personna];
            return acc;
          }, {});
        }
      } else {
        steps = STEP_BY_personnas[formParams.personnas[0]];
      }

      if (steps && steps.length > 0) {
        setRootNode(getStepTree(steps));
      }
    } else {
      setRootNode(getStepTree(configToUse));
    }
  }, [currentConfig]);

  useEffect(() => {
    setState(prevState =>
      !prevState.activeNode
        ? {
            ...prevState,
            activeNode: rootNode
          }
        : prevState
    );
  }, [rootNode]);

  const setNextNodeState = (nextActiveNode: StepNode, actualNodeId: number) => {
    setState({
      activeNode: nextActiveNode,
      path: [...state.path, actualNodeId],
      activeStep: state.activeStep + 1
    });
  };

  const submitIntermediaryStep = (successCallback: (data: any) => void) => {
    let node: StepNode | undefined = rootNode;
    if (!node) return;
    let isLast: boolean | undefined;
    let toSubmit: any = {};
    setSubmitIntermediaryStepState({ status: SerieSubmitStatus.ONGOING });
    // build the object to submit by running the accumulator function of each
    do {
      isLast = node && node?.config.stepCode === state.activeNode?.config.stepCode;
      const accumulator = node.accumulateStateToSerie || defaultAccumulateStateToSeries;
      toSubmit = accumulator(toSubmit, node.state);
      node = node.selectNextStep(node.state);
    } while (node && !isLast);
    castToISODateRecursively(toSubmit);
    submitFormToApi(convertEmptyStringsToNull(toSubmit), successCallback);
  };

  const submitForms = () => {
    let node: StepNode | undefined = rootNode;
    if (!node) return;
    let toSubmit: any = {};
    // build the object to submit by running the accumulator function of each
    do {
      const accumulator = node.accumulateStateToSerie || defaultAccumulateStateToSeries;
      toSubmit = accumulator(toSubmit, node.state);
      node = node.selectNextStep(node.state);
    } while (node);
    const projectNumber = getUrlParam(location.search, 'p');
    if (projectNumber) {
      toSubmit.numeroProjet = projectNumber;
    }
    setSubmitState({ status: SerieSubmitStatus.ONGOING });
    if (isFormulaireProjetManuel && formParams.personnas.length === 1) {
      toSubmit['type'] = formParams.personnas[0];
    }

    if (formParams.type === 'PROJET') {
      switch (formParams.cible) {
        case 'PREVOYANCE': {
          toSubmit.besoins = addBesoinsPrevoyance(toSubmit);
          break;
        }
        case 'SANTE_PARTICULIER': {
          toSubmit.niveaux = addNiveauxSanteParticulier(toSubmit);
          break;
        }
        case 'GAV': {
          toSubmit.besoin = {};
          break;
        }
      }
    }
    if (formParams.type === 'MISE_EN_RELATION') {
      toSubmit.dateRappel = creerDateRappel(toSubmit);
    }

    castToISODateRecursively(toSubmit);

    trimStringRecursively(toSubmit, ['nom', 'prenom']);

    toSubmit.captchaToken = captchaToken;

    // submit
    submitFormToApi(convertEmptyStringsToNull(toSubmit));
  };

  const lastFormState = useMemo(() => getLastFormStateInstance(), []);
  const progression =
    (((state?.activeNode?.position || 0) + 1) / (state.activeStep + (state?.activeNode?.maxRemainingSteps || 0) + 1)) *
    100;

  const keyParams = useMemo(() => {
    return `FORMULAIRE_${formParams.type}`;
  }, []);

  function handleIntermediaryStepResponse(id: number, nextActiveNode?: StepNode) {
    return (data: any) => {
      if (!nextActiveNode) return;
      setNextNodeState(nextActiveNode, id);
      if (data.updatedInitialValuesFormsParams && updateRequestFormParams) {
        updateRequestFormParams({
          ...formParams,
          request: {
            ...{ ...formParams.request },
            ...data.updatedInitialValuesFormsParams,
            stepCode: state.activeNode?.children[0].config.stepCode
          }
        });
      }
    };
  }

  if (!state.activeNode) {
    return null;
  }

  const readOnly = isReadOnly(formParams.type, currentConfig, state.activeNode, formParams?.request?.stepCode);
  const titleLeft = getTitlePanelLeft(currentConfig, formParams, keyParams);

  const goNextStep = (newFormValues: any, accumulateStateToSerie?: AccumulateStateToSerie) => {
    // update the state of the node
    if (state.activeNode) {
      state.activeNode.state = newFormValues;
      previousStateRef.current = merge(previousStateRef.current, state.activeNode.state);
      const id = state.activeNode.id;
      // update the (optionnal) accumulator function of the node
      state.activeNode.accumulateStateToSerie = accumulateStateToSerie;
      const nextActiveNode = state.activeNode.selectNextStep(newFormValues);
      if (state.activeNode.config.submit && !readOnly) {
        submitIntermediaryStep(handleIntermediaryStepResponse(id, nextActiveNode));
      } else {
        if (nextActiveNode) {
          setNextNodeState(nextActiveNode, state.activeNode.id);
        } else {
          submitForms();
        }
      }
    }
  };

  return (
    <>
      <LeftPane
        title={titleLeft}
        subTitle={formParams.parametres[`${keyParams}_SOUSTITRE_PAGE_GAUCHE`]}
        description={state.activeNode.config.description}
        urlLogo={formParams.urlLogo}
        urlFicheLegale={urlFicheLegale}
        siteCourtier={distributeur?.cabinet?.siteWeb}
        nomCourtier={distributeur?.cabinet?.raisonSociale}
        hideFicheLegale={formParams.parametres.FORMULAIRE_LIEN_FICHE_CABINET ?? 'false'}
        labelButtonBackToSite={formParams.parametres.FORMULAIRE_LIBELLE_BOUTON_RETOUR_AU_SITE}
      />
      <RightPane
        ref={rightPaneRef}
        mobileFooter={
          isDisplayMobileFooter
            ? {
                siteCourtier: distributeur?.cabinet?.siteWeb as string,
                nomCourtier: distributeur?.cabinet?.raisonSociale as string,
                title: titleLeft
              }
            : undefined
        }
        labelButtonBackToSite={formParams.parametres.FORMULAIRE_LIBELLE_BOUTON_RETOUR_AU_SITE}
      >
        {!breadCrumbConf && !hideProgressBar && (
          <Line
            percent={progression}
            strokeWidth={0.5}
            trailWidth={0.5}
            strokeColor={'var(--color-secondary)'}
            trailColor={'var(--color-secondary-light)'}
          />
        )}
        {breadCrumbConf &&
          !state.activeNode.config.noBreadCrumb &&
          !state.activeNode.config.breadcrumbBelowPrecButton && (
            <BreadCrumb steps={breadCrumbConf.steps} activeStep={state.activeNode?.config.stepCode} />
          )}
        {avecRetour && (
          <FormPrecButton
            activeStep={state.activeStep}
            onClick={() => {
              // get state of current step
              const newFormValues = lastFormState.get();
              // get previous node in path
              if (!state.activeNode?.parents) return;
              let nextActiveNode: StepNode | undefined = _.find(
                state.activeNode?.parents,
                parent => parent.id === state.path[state.activeStep - 1]
              );
              if (!nextActiveNode) {
                nextActiveNode = _.find(
                  state.activeNode?.parents[0]?.parents,
                  parent => parent.id === state.path[state.activeStep - 1]
                );
              }
              if (nextActiveNode) {
                // update state of node
                state.activeNode.state = newFormValues;
                setState(prevState => ({
                  activeNode: nextActiveNode,
                  path: prevState.path.slice(undefined, -1),
                  activeStep: prevState.activeStep - 1
                }));
              }
            }}
          />
        )}
        {breadCrumbConf &&
          !state.activeNode.config.noBreadCrumb &&
          state.activeNode.config.breadcrumbBelowPrecButton && (
            <BreadCrumb
              steps={breadCrumbConf.steps}
              activeStep={state.activeNode?.config.stepCode}
              currentStep={formParams.request.stepCode}
            />
          )}
        {/*// Put activeStep number to key of stepComponent to assure re-render if same component used accross several steps*/}
        <state.activeNode.config.component
          readOnly={readOnly}
          submitIntermediaryStepState={submitIntermediaryStepState}
          key={state.activeStep}
          data={state.activeNode.config.data}
          setLastFormState={lastFormState.set}
          cible={formParams.cible}
          initialFormValues={formParams.request}
          personnas={formParams.personnas}
          previousFormValues={previousStateRef.current}
          champsPersonnalises={formParams.champsPersonnalises}
          formValues={state.activeNode.state}
          nomCourtier={distributeur?.cabinet?.raisonSociale}
          showDureeSejour={formParams.parametres[`${keyParams}_SHOW_DUREE_SEJOUR`]}
          cocheConsentement={formParams.parametres.FORMULAIRE_COCHE_CONSENTEMENT}
          contenuConsentement={formParams.parametres.FORMULAIRE_CONTENU_CONSENTEMENT}
          goNextStep={goNextStep}
          showCaptcha={state.activeNode.config.data?.showCaptcha ?? false}
          setCaptchaToken={setCaptchaToken}
        />

        {urlFicheLegale && (formParams.parametres.FORMULAIRE_LIEN_FICHE_CABINET ?? 'false') === 'false' && (
          <div className="fiche-download-right">
            <ObfuscatedLink href={urlFicheLegale}>Informations légales du cabinet</ObfuscatedLink>
          </div>
        )}
      </RightPane>
      <SubmitOverlay
        hideOverlay={() => setSubmitState({ status: SerieSubmitStatus.PENDING })}
        submitStatus={submitState.status}
        errorMessage={submitState.message}
        siteCourtier={distributeur?.cabinet?.siteWeb as string}
        title={formParams.parametres[`${keyParams}_TITRE_PAGE_CONFIRMATION`]}
        subTitle={formParams.parametres[`${keyParams}_SOUSTITRE_PAGE_CONFIRMATION`]}
        nomCommercial={formParams.parametres.FORMULAIRE_MARQUE_COMMERCIALE}
        baseLine={formParams.parametres.FORMULAIRE_BASELINE}
        labelButtonBackToSite={formParams.parametres.FORMULAIRE_LIBELLE_BOUTON_RETOUR_AU_SITE}
      />
      <UnavailableOverlay
        title="Ce lien n’est plus actif, nous vous invitons à contacter notre cabinet."
        nomCommercial={formParams.parametres.FORMULAIRE_MARQUE_COMMERCIALE}
        baseLine={formParams.parametres.FORMULAIRE_BASELINE}
        isOpen={
          getUrlParam(location.search, 'p') != undefined &&
          (!formParams.request || !formParams.request?.numeroProjet === null)
        }
      />
    </>
  );
};

export default FormSerie;
