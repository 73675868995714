import { faCheckCircle, faComment, faCompass } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';

import { ColoredText } from '../../components/ColoredText/ColoredText';
import { LinkWithIcon } from '../../components/Link/LinkWithIcon.component';
import { TitleWithIcon } from '../../components/Title/TitleWithIcon.component';
import { fetchLibelleCodif } from '../../fetches/options.fetch';
import { useFetch } from '../../hooks/useFetch.hook';
import { getCodifLabelFromOptionsIfExist } from '../../utils';
import './FormDemandeAdhesionGlobal.scss';

import './FormDemandeAdhesionStepInit.scss';
import { Typo } from './ReglesGestion/Typo';

type StepDescriptionProps = {
  title: string;
  description: string;
  className?: string;
};

const StepDescription = ({ title, description, className }: StepDescriptionProps): ReactElement => {
  return (
    <div>
      <h5 className="form-font-smaller">
        <ColoredText className={classNames(className || 'secondary', 'step-title')} text={title} />
      </h5>
      <p className={'description-label form-font-regular'}>{description}</p>
    </div>
  );
};

function getLabelCategorie(categorie: string | undefined): string | undefined {
  if (categorie === 'RECUEIL_BESOIN' || categorie === 'FIC') return "Fiche d'information et Conseil";
  else if (categorie === 'FICHE_INFORMATIONS_LEGALES') return 'Description de notre cabinet';
  else if (categorie === 'TABLEAU_COMPARATIF') return 'Comparatif des offres proposés';
  else if (categorie === 'SYNTHESE_DEMARCHE') return "Rapport d'information des démarches de votre courtier";

  return undefined;
}

export default function FormDemandeAdhesionStepInit(props: FormProps<any>): ReactElement {
  const { initialFormValues } = props;
  const typologies = useFetch(fetchLibelleCodif);

  const isDocument = initialFormValues?.documentsInfos?.length > 0;
  const currentTypo = initialFormValues.typologieLabel.split(':')[0];
  const withInfos = Object.values(Typo).includes(currentTypo);

  const handleNextClick = () => {
    props.goNextStep({ numeroProjet: initialFormValues?.numeroProjet });
  };

  const getNumberStepSignature = () => {
    if (withInfos) {
      if (initialFormValues?.avecFrais) {
        return 5;
      }
      return 4;
    }
    if (initialFormValues?.avecFrais) {
      return 4;
    }
    return 3;
  };

  return (
    <div className="form-adhesion">
      <h2 className="form-font-title">Avant de commencer, quelques précisions</h2>
      <TitleWithIcon
        icon={<FontAwesomeIcon size={'2x'} icon={faCheckCircle} />}
        title={
          <h3 className="form-font-large form-init-title">
            En choisissant votre contrat{' '}
            {getCodifLabelFromOptionsIfExist(initialFormValues?.typologieLabel, typologies)} avec nous,
            <br /> vous êtes sûr d’être <ColoredText className={'secondary'} text={'bien couvert où que soyez.'} />
          </h3>
        }
      />
      {isDocument && (
        <>
          <TitleWithIcon
            icon={<FontAwesomeIcon size={'2x'} icon={faComment} />}
            title={
              <h3 className="form-font-large form-init-title">
                Vous pouvez télécharger les <ColoredText className={'secondary'} text={'documents suivants'} /> afin
                d&apos;avoir <br /> l&apos;exhaustivité des informations à connaître en mains.
              </h3>
            }
          />
          <div className={'marged-block-with-icon'}>
            {initialFormValues?.documentsInfos.map(
              (doc: any, iDoc: any) =>
                !doc.proprietes.PRODUIT_CODE && (
                  <LinkWithIcon
                    key={iDoc}
                    className={'form-font-smaller'}
                    icon={<FontAwesomeIcon size={'2x'} icon={faDownload} />}
                    labelLink={getLabelCategorie(doc.categories[0]) || doc.titre || doc.fileUploadedName}
                    link={doc.url}
                  />
                )
            )}
          </div>
        </>
      )}
      <TitleWithIcon
        icon={<FontAwesomeIcon size={'2x'} icon={faCompass} />}
        title={<h3 className="form-font-large form-init-title">Voilà comment vont se dérouler les choses :</h3>}
      />
      <div className={'marged-block'}>
        <StepDescription
          title={'ETAPE 1'}
          description={'Présentation et comparaison des offres sélectionnées pour vous'}
          className={
            initialFormValues?.stepCode !== 'INIT' && initialFormValues?.stepCode !== 'OFFRE' ? 'green' : 'secondary'
          }
        />
        {withInfos && (
          <StepDescription
            title={'ETAPE 2'}
            description={'Informations complémentaires (en fonction du produit choisi)'}
            className={
              initialFormValues?.stepCode === 'FRAIS' || initialFormValues?.stepCode === 'SIGNATURE'
                ? 'green'
                : 'secondary'
            }
          />
        )}
        <StepDescription
          title={`ETAPE ${withInfos ? '3' : '2'}`}
          description={'Pièces justificatives'}
          className={
            initialFormValues?.stepCode === 'FRAIS' || initialFormValues?.stepCode === 'SIGNATURE'
              ? 'green'
              : 'secondary'
          }
        />
        {initialFormValues?.avecFrais === true && (
          <StepDescription
            title={`ETAPE ${withInfos ? '4' : '3'}`}
            description={'Paiement des frais'}
            className={initialFormValues?.stepCode === 'SIGNATURE' ? 'green' : 'secondary'}
          />
        )}
        <StepDescription
          title={`ETAPE ${getNumberStepSignature()}`}
          description={'Récapitulatif et signature de votre offre'}
        />
      </div>
      <form onSubmit={handleNextClick}>
        <div className="next-button-container">
          <FormNextButton
            className="form-adhesion-next-button no-default-style form-font-regular"
            showCaptcha={props.showCaptcha}
            setCaptchaToken={props.setCaptchaToken}
          >
            Je démarre
          </FormNextButton>
        </div>
      </form>
    </div>
  );
}
