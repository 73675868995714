import { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';
import { SubmitState } from './components/FormSerie/FormSerie.component';

export interface Option<T = any, M = Record<string, any> | undefined> {
  value: T;
  label: string;
  meta?: M;
}

export interface FormParams {
  id: string;
  type: string;
  actif: boolean;
  urlLogo: string;
  avecFrais: boolean;
  montantFrais: number;
  effetDate: string;
  personnas: string[];
  cible: string;
  champsPersonnalises: any;
  parametres: {
    FORMULAIRE_COULEUR_PRIMAIRE: string;
    FORMULAIRE_COULEUR_SECONDAIRE: string;
    FORMULAIRE_MARQUE_COMMERCIALE: string;
    FORMULAIRE_BASELINE: string;
    FORMULAIRE_LIEN_FICHE_CABINET: string;
    FORMULAIRE_COCHE_CONSENTEMENT: string;
    FORMULAIRE_CONTENU_CONSENTEMENT: string;
    FORMULAIRE_LIBELLE_BOUTON_RETOUR_AU_SITE: string;
    [key: string]: string;
  };
  request: Record<string, any>;
  step?: string;
}

export type ObjectKeysString = {
  [key: string]: any;
};

export type FormProps<T, D = any> = {
  data?: D;
  formValues: T;
  previousFormValues?: any;
  personnas: string[];
  cible?: string;
  initialFormValues?: any;
  champsPersonnalises: ChampPersonnalise[] | null;
  goNextStep: (newState: T | any, accumulateStateToSerie?: AccumulateStateToSerie<T>) => void;
  showCaptcha: boolean;
  setCaptchaToken: Dispatch<SetStateAction<string | undefined>>;
  setLastFormState: (newState: T) => void;
  cocheConsentement: string;
  contenuConsentement: string;
  showDureeSejour?: string;
  submitIntermediaryStepState?: SubmitState;
  readOnly?: boolean;
  nomCourtier?: string;
};

export type FormComponent<T> = (props: FormProps<T>) => ReactElement;

export type LastFormStateInstance<T> = {
  get: () => T;
  set: (newState: T) => void;
};

export interface Config {
  [key: string]: { forms: FormComponent<any>[] };
}

export interface CabinetResponse {
  raisonSociale: string;
  siteWeb: string;
}

export interface DistributeurReponse {
  cabinet: CabinetResponse;
}

export interface FormStepConfig {
  component: FormComponent<any>;
  data?: { showCaptcha?: boolean } & Record<string, any>;
  description?: any;
  title?: string;
  submit?: boolean;
  formData?: boolean;
  init?: boolean;
  stepCode?: string;
  submitIntermediaryStepState?: SubmitState;
  noBreadCrumb?: boolean;
  breadcrumbBelowPrecButton?: boolean;
}

export interface FormNextStepsConfig {
  selectNextStep: (prevStepState: any) => string | undefined;
  paths: {
    [key: string]: FormStepConfigWithNext[];
  };
}

export interface FormConfigWithUrl {
  endpoint?: string;
  prefetch?: Promise<any>[];
  formSteps: FormStepConfigWithNext[];
  breadCrumbConf?: any;
  hideProgressBar?: boolean;
  avecRetour?: boolean;
  stepOrder?: string[];
  isTitleLeftCommercial?: boolean;
  displayMobileFooter?: boolean;
}

export interface FormStepConfigWithNext extends FormStepConfig {
  next?: FormNextStepsConfig;
}

export type AccumulateStateToSerie<T = any> = (serieState: any, state: T) => any;

export interface StepNode {
  id: number; // uniq id
  state: any; // values of the form linked to the node
  pathName?: string; // name of the path associated to the node
  config: FormStepConfig; // config of the form
  position: number; // position in tree
  maxRemainingSteps: number; // max remaining steps in current path
  accumulateStateToSerie?: AccumulateStateToSerie;
  selectNextStep: (prevStepState: any) => StepNode | undefined;
  children: StepNode[];
  parents?: StepNode[];
}

export enum SerieSubmitStatus {
  PENDING,
  ONGOING,
  SUCCEEDED,
  FAILED
}

export enum AppFailureType {
  ERROR,
  INFO
}

export type DisplayFailureFunction = (title: string, content: ReactNode, type?: AppFailureType) => void;

export type SetLoadingStateFunction = (newState: boolean) => void;

export interface SeriesConfigs {
  [keys: string]: FormConfigWithUrl;
}

export type StepDescription = {
  beforeTitle?: string;
  title?: string;
  hideTitleIcon?: boolean;
  description?: string;
  descriptionAsHTML?: boolean;
};

export type StepDescriptionList = {
  [key: string]: StepDescription;
};

export type MappingFunction = (sourceData: any, key: any) => any;

export type Contrat = {
  produit: {
    assureur: {
      code: string | undefined | null;
    };
  };
  anneeDebut: Date | undefined | null;
  cotisation: {
    fractionnement: string | undefined;
    fractionnementLibelle: string | undefined;
    montantAnnuel: number | undefined | null;
  };
  commentaire: string | undefined;
  typologieCode: string | undefined | null;
};

export type ChampPersoValeuresRestreinte = {
  id: string;
  valeur: string;
};

export type ChampPersonnalise = {
  libelle: string;
  description: string;
  type: string;
  valeuresRestreintes: ChampPersoValeuresRestreinte[];
  valeurParDefaut: string | null;
  obligatoire: boolean;
  ordre: number;
  id: 224;
};

export const StepCodeAdhesion = {
  OFFRE: 'OFFRE',
  INFOS: 'INFOS',
  PJ: 'PJ',
  FRAIS: 'FRAIS',
  SIGNATURE: 'SIGNATURE'
};

export type DocumentsSouscription = {
  document: {
    fileName: string;
    fileUploadedName: string;
  };
};
