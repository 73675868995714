import React, { ReactElement } from 'react';
import './FormMandatSepaFooter.scss';

export default function FormMandatSepaFooter(): ReactElement {
  return (
    <div className="formMandatSepaFooter">
      <p className="formMandatSepaFooter__title">Protection des données personnelles</p>
      <p className="formMandatSepaFooter__text">
        Les informations recueillies dans le présent document, ou contenues dans les pièces demandées, ont pour
        finalités la gestion et l’exécution du contrat ainsi que la gestion du risque. Vous disposez notamment des
        droits d’accès, de rectification, d’opposition, de portabilité, d&apos;effacement et de limitation du traitement
        vous concernant. Pour l&apos;exercice de vos droits, adressez un courrier simple au responsable de traitement :
        Tutélaire, 157 avenue de France, 75013 Paris ou un courriel à : donneespersonnelles@tutelaire.fr. Pour plus
        d’informations relatives à la gestion de vos données, vous pouvez à tout moment consulter notre politique de
        confidentialité sur le site internet www.tutelaire.fr ou la demander par courrier simple au responsable de
        traitement de Tutélaire.
      </p>
    </div>
  );
}
