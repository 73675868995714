import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import FieldUploadFile from '../../components/FieldUploadFile/FieldUploadFile';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import { DocumentResiliationTutelaireProps } from '../../hooks/useResiliationTutelaire.hook';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { DocumentsSouscription, FormProps } from '../../types';
import { assignValuesFromSource, createFormData } from '../../utils';
import parseSearch from '../../utils/parseSearch';
import Yup from '../../utils/Yup';
import FormResiliationFooter from './FormResiliationFooter.component';
import { PASSPORT_CNI_OPTIONS } from '../../consts/consts';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';

interface FormValues {
  typeDocumentIdentite?: string;
  documents?: Record<string, DocumentsSouscription>;
}

const enum CategorieDocument {
  PIECE_IDENTITE_RECTO = 'PIECE_IDENTITE_RECTO',
  PIECE_IDENTITE_VERSO = 'PIECE_IDENTITE_VERSO',
  PASSEPORT = 'PASSEPORT'
}

const defaultState: FormValues = {
  typeDocumentIdentite: '',
  documents: {
    pieceIdentiteRecto: {
      document: {
        fileName: '',
        fileUploadedName: ''
      }
    },
    pieceIdentiteVerso: {
      document: {
        fileName: '',
        fileUploadedName: ''
      }
    },
    passeport: {
      document: {
        fileName: '',
        fileUploadedName: ''
      }
    }
  }
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  documents: Yup.object(),
  typeDocumentIdentite: Yup.string().required()
});

function getInitialValues(initialFormValues: any): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormResiliationImportDocuments(props: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);
  const [listDocuments, setListDocuments] = useState<DocumentResiliationTutelaireProps[]>([]);

  const idFormulaire = parseSearch(window.location.search, 'id');
  if (!idFormulaire) throw new Error("Le formulaire n'a pas été trouvé.");

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
    watch
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = async (data: FormValues) => {
    props.goNextStep({ ...data, documentsToUpload: listDocuments });
  };

  const handleUpload = async (file: File, categorie: CategorieDocument) => {
    const data = {
      fileContent: file,
      fileUploadedName: file.name
    };
    const fd = createFormData(data);
    setListDocuments(prevState => {
      return [
        ...prevState,
        {
          document: fd,
          fileName: file.name,
          fileUploadedName: file.name,
          numeroClient: undefined,
          categorie: categorie,
          idFormulaire: idFormulaire
        }
      ];
    });
  };

  const handleRemoveFile = async (categorie: CategorieDocument) => {
    setListDocuments(prevState => {
      return prevState.filter(d => d.categorie !== categorie);
    });
  };

  const typeDocumentIdentite = watch('typeDocumentIdentite');
  const cniRecto = watch('documents.pieceIdentiteRecto.document.fileUploadedName');
  const cniVerso = watch('documents.pieceIdentiteVerso.document.fileUploadedName');
  const passeport = watch('documents.passeport.document.fileUploadedName');
  const [showNextButton, setShowNextButton] = useState(false);
  useEffect(() => {
    if (
      (typeDocumentIdentite === 'CNI' && cniRecto && cniVerso) ||
      (typeDocumentIdentite === 'PASSEPORT' && passeport)
    ) {
      setShowNextButton(true);
    } else {
      setShowNextButton(false);
    }
  });
  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <p className="title">Importez vos documents d&apos;identité</p>

      <InputRadioButtonInline
        labelField={"Quel type de pièce d'identité souhaitez-vous importer ?"}
        {...register(`typeDocumentIdentite`)}
        options={PASSPORT_CNI_OPTIONS}
        column={2}
        errors={errors}
      />

      {typeDocumentIdentite === 'CNI' && (
        <>
          <FieldUploadFile
            className={'file-input form-font-regular'}
            label="Pièce d'identité recto"
            onFileUploaded={(file: any) => {
              handleUpload(file, CategorieDocument.PIECE_IDENTITE_RECTO);
              setValue('documents.pieceIdentiteRecto.document.fileUploadedName', file.name);
              trigger('documents.pieceIdentiteRecto.document.fileUploadedName');
            }}
            onFileDelete={() => {
              handleRemoveFile(CategorieDocument.PIECE_IDENTITE_RECTO);
              setValue('documents.pieceIdentiteRecto.document.fileUploadedName', '');
              trigger('documents.pieceIdentiteRecto.document.fileUploadedName');
            }}
            value={getValues('documents.pieceIdentiteRecto')}
            errors={errors}
            register={register}
            name={`documents.pieceIdentiteRecto`}
            readOnly={props.readOnly}
          />
          <FieldUploadFile
            className={'file-input form-font-regular'}
            label="Pièce d'identité verso"
            onFileUploaded={(file: any) => {
              handleUpload(file, CategorieDocument.PIECE_IDENTITE_VERSO);
              setValue('documents.pieceIdentiteVerso.document.fileUploadedName', file.name);
              trigger('documents.pieceIdentiteVerso.document.fileUploadedName');
            }}
            onFileDelete={() => {
              handleRemoveFile(CategorieDocument.PIECE_IDENTITE_VERSO);
              setValue('documents.pieceIdentiteVerso.document.fileUploadedName', '');
              trigger('documents.pieceIdentiteVerso.document.fileUploadedName');
            }}
            value={getValues('documents.pieceIdentiteVerso')}
            errors={errors}
            register={register}
            name={`documents.pieceIdentiteVerso`}
            readOnly={props.readOnly}
          />
        </>
      )}
      {typeDocumentIdentite === 'PASSEPORT' && (
        <FieldUploadFile
          className={'file-input form-font-regular'}
          label="Passeport"
          onFileUploaded={(file: any) => {
            handleUpload(file, CategorieDocument.PASSEPORT);
            setValue('documents.passeport.document.fileUploadedName', file.name);
            trigger('documents.passeport.document.fileUploadedName');
          }}
          onFileDelete={() => {
            handleRemoveFile(CategorieDocument.PASSEPORT);
            setValue('documents.passeport.document.fileUploadedName', '');
            trigger('documents.passeport.document.fileUploadedName');
          }}
          value={getValues('documents.passeport')}
          errors={errors}
          register={register}
          name={`documents.passeport`}
          readOnly={props.readOnly}
        />
      )}

      {showNextButton && (
        <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken} disabled={!isValid}>
          Suivant
        </FormNextButton>
      )}

      <FormResiliationFooter />
    </form>
  );
}
