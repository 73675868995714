import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import FormDemandeAdhesionFrais from './FormDemandeAdhesionFrais.component';
import './FormDemandeAdhesionStepInit.scss';

interface FormValues {
  montantFrais: number | undefined;
  numeroPaiementStripe: string | undefined;
  publicClefApiStripe: string | undefined;
}

export default function FormDemandeAdhesionStepFrais(props: FormProps<FormValues>): ReactElement {
  const { initialFormValues } = props;

  const stripePromise = loadStripe(initialFormValues?.publicClefApiStripe, {
    locale: 'fr-FR'
  });

  const appearance: Appearance = {
    theme: 'none'
  };

  return (
    <Elements stripe={stripePromise} options={{ appearance: appearance }}>
      <FormDemandeAdhesionFrais
        champsPersonnalises={props.champsPersonnalises}
        cocheConsentement={props.cocheConsentement}
        contenuConsentement={props.contenuConsentement}
        formValues={props.formValues}
        goNextStep={props.goNextStep}
        personnas={props.personnas}
        setLastFormState={props.setLastFormState}
        cible={props.cible}
        data={props.data}
        initialFormValues={props.initialFormValues}
        readOnly={props.readOnly}
        submitIntermediaryStepState={props.submitIntermediaryStepState}
        setCaptchaToken={props.setCaptchaToken}
        showCaptcha={props.showCaptcha}
      />
    </Elements>
  );
}
