import { FormProps } from '../../types';
import React, { ReactElement } from 'react';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import Yup from '../../utils/Yup';
import './FormResiliationDemande.scss';
import FormResiliationFooter from './FormResiliationFooter.component';

interface FormValues {}

const defaultState: FormValues = {};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({}).defined();

export default function FormResiliationDemande(props: FormProps<FormValues>): ReactElement {
  const {
    handleSubmit,
    formState: {}
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultState // always reset to default state
  });
  const handleNextClick = async (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formResiliationDemande">
      <p className="title">Demande de résiliation</p>
      <p className="intro">
        Vous souhaitez demander la résiliation de votre contrat. Vous pouvez le faire en complétant ce formulaire. Votre
        demande sera directement réceptionnée et traitée par vos interlocuteurs.
      </p>
      <p>
        Cette opération s’effectue entièrement en ligne. Elle est réservée aux adhérents Tutélaire munis d’une adresse
        e-mail active. Dans le cas contraire, vous pouvez télécharger les documents nécessaires, les imprimer et nous
        les renvoyer comme indiqué dans le formulaire, dans la rubrique Mes services.
      </p>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
      <FormResiliationFooter />
    </form>
  );
}
