import { yupResolver } from '@hookform/resolvers/yup';
import FormConsent from 'components/FormConsent/FormConsent.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import * as ibantools from 'ibantools';
import { merge } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { AccumulateStateToSerie, FormProps, SerieSubmitStatus, StepCodeAdhesion } from 'types';
import FieldUploadFile from '../../components/FieldUploadFile/FieldUploadFile';
import InputText from '../../components/Input/InputText/InputText.component';
import { ErrorLabel } from '../../components/Input/parts/ErrorLabel/ErrorLabel.component';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { mapByKey } from '../../utils';
import Yup from '../../utils/Yup';

import './FormDemandeAdhesionStepPJ.scss';

const BASE_DEFAULT_STATE = {
  stepCode: 'PJ',
  consentement: false,
  documentsSouscriptions: {},
  rib: {
    beneficiaire: '',
    iban: '',
    bic: ''
  }
};

const isIBANValid = (value: string | undefined) => {
  return ibantools.isValidIBAN(value ?? '');
};

const isBICValid = (value: string | undefined) => {
  return ibantools.isValidBIC(value ?? '');
};

function useDocsWithForm(
  docs: any,
  baseValidationSchema = {},
  baseTemplate = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialValues: any
) {
  if (docs === undefined)
    return {
      validationSchema: undefined,
      initialValues: undefined
    };
  if (docs === null)
    return {
      validationSchema: baseValidationSchema,
      template: baseTemplate
    };
  const docValidation = docs?.reduce((obj: any, item: any) => {
    obj = {
      ...obj,
      [item.numero]: Yup.object({
        document: Yup.object({
          fileUploadedName: Yup.string().required(),
          url: Yup.string()
        })
      })
    };
    return obj;
  }, {});

  const extendedValidationSchema = Yup.object().shape({
    ...baseValidationSchema,
    documentsSouscriptions: Yup.object().shape({
      ...docValidation
    })
  });
  const extendedTemplate = docs?.reduce(
    (obj: any, item: any) => {
      obj = {
        ...obj,
        documentsSouscriptions: {
          ...obj.documentsSouscriptions,
          [item.numero]: {
            ...obj.documentsSouscriptions[item.numero],
            document: {
              ...item.document,
              fileUploadedName: item?.document?.fileUploadedName,
              url: item?.document?.url
            }
          }
        }
      };
      return obj;
    },
    { ...baseTemplate, documentsSouscriptions: {} }
  );
  return {
    validationSchema: extendedValidationSchema,
    template: extendedTemplate
  };
}

export default function FormDemandeAdhesionStepPJ(props: FormProps<any>): ReactElement {
  const { initialFormValues } = props;

  const showInformationRIB = initialFormValues?.showInformationRIB ?? false;

  const BASE_VALIDATION_SCHEMA = {
    stepCode: Yup.string().required(),
    consentement: Yup.boolean().isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.'),
    rib: Yup.object({
      beneficiaire: showInformationRIB ? Yup.string().required() : Yup.string().notRequired(),
      iban: showInformationRIB
        ? Yup.string().test('iban', "La valeur de l'IBAN n'est pas valide", isIBANValid).required()
        : Yup.string().notRequired(),
      bic: showInformationRIB
        ? Yup.string().test('bic', "La valeur de du BIC n'est pas valide", isBICValid).required()
        : Yup.string().notRequired()
    })
  };

  type FormValues = {
    consentement: boolean;
    stepCode: string;
    documentsSouscriptions: Record<string, unknown>;
    rib: {
      beneficiaire: string;
      iban: string;
      bic: string;
    };
  };

  const defaultState: FormValues = {
    consentement: false,
    stepCode: StepCodeAdhesion.PJ,
    documentsSouscriptions: {},
    rib: {
      beneficiaire: '',
      iban: '',
      bic: ''
    }
  };

  const getInitialFormValues: any = (initialFormValues: any) => {
    const state: FormValues = defaultState;
    state.documentsSouscriptions = mapByKey(initialFormValues.documentsSouscriptions || [], 'numero');
    return state;
  };

  const initialValues = getInitialFormValues(initialFormValues);

  const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
    state.documentsSouscriptions = files;
    return merge(serieState, state);
  };

  const [files, setFiles] = useState<any>([]);

  const { validationSchema } = useDocsWithForm(
    initialFormValues?.documentsSouscriptions,
    BASE_VALIDATION_SCHEMA,
    BASE_DEFAULT_STATE,
    initialFormValues
  );

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
    register
  } = useSerieForm<any>(props.setLastFormState, {
    resolver: yupResolver(validationSchema as any),
    defaultValues: initialValues || defaultState
  });

  const docsValue = getValues()?.documentsSouscriptions;

  const handleNextClick = (data: any) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  useEffect(() => {
    setValue('stepCode', 'PJ');
  }, []);

  useEffect(() => {
    // Transform values added as array according to form schema
    if (docsValue && Array.isArray(docsValue)) {
      setValue('documentsSouscriptions', mapByKey(docsValue, 'numero'));
    }
  }, [docsValue]);

  return (
    <div className="form-adhesion">
      <div className={'adhesion-step-pj'}>
        <form onSubmit={handleSubmit(handleNextClick)}>
          {initialFormValues?.documentsSouscriptions?.length > 0 && (
            <>
              <h2>
                Documents à fournir <br />
                <small className="form-font-regular label-pj">Veuillez importer les documents suivants :</small>
              </h2>
              <div className="file-input-container">
                {initialFormValues?.documentsSouscriptions?.map((doc: any) => {
                  return (
                    <FieldUploadFile
                      className={'file-input form-font-regular'}
                      label={doc.categorie}
                      key={doc.numero}
                      onFileUploaded={(file: any) => {
                        setValue(`documentsSouscriptions[${doc.numero}].document.fileUploadedName`, file.name);
                        trigger(`documentsSouscriptions[${doc.numero}].document.fileUploadedName`);
                        setFiles([
                          ...files,
                          {
                            ...doc,
                            document: {
                              fileContent: file,
                              fileUploadedName: file.name
                            }
                          }
                        ]);
                      }}
                      onFileDelete={() => {
                        setValue(`documentsSouscriptions[${doc.numero}].document.fileUploadedName`, '');
                        trigger(`documentsSouscriptions[${doc.numero}].document.fileUploadedName`);
                        setFiles(files.filter((fileInList: any) => fileInList.numero === doc.numero));
                      }}
                      value={getValues(`documentsSouscriptions[${doc.numero}]`)}
                      errors={errors}
                      register={register}
                      name={`documentsSouscriptions[${doc.numero}]`}
                      readOnly={props.readOnly}
                    />
                  );
                })}
              </div>
            </>
          )}

          {showInformationRIB && (
            <div>
              <h2>
                Renseignement du RIB <br />
                <small className="form-font-regular label-pj">* Champs obligatoire</small>
              </h2>
              <InputText label={`Bénéficiaire *`} required {...register('rib.beneficiaire')} errors={errors} />
              <InputText label={`IBAN *`} required {...register('rib.iban')} errors={errors} />
              <InputText label={`BIC *`} required {...register('rib.bic')} errors={errors} />
            </div>
          )}

          <input {...register('stepCode')} hidden />
          <div className="form-consentement-container">
            <FormConsent
              contenuConsentement={props.contenuConsentement}
              control={control}
              errors={errors}
              label={props.cocheConsentement}
              {...register('consentement')}
            />
          </div>

          <div className="next-button-container">
            <FormNextButton
              className="form-adhesion-next-button no-default-style form-font-regular"
              onClick={props.readOnly ? () => handleNextClick({}) : undefined}
              loading={props.submitIntermediaryStepState?.status === SerieSubmitStatus.ONGOING}
              showCaptcha={props.showCaptcha}
              setCaptchaToken={props.setCaptchaToken}
            >
              {props.readOnly ? 'Suivant' : 'Je valide ces informations'}
            </FormNextButton>
            {props.submitIntermediaryStepState?.status === SerieSubmitStatus.FAILED && (
              <div className="error-submit">
                <ErrorLabel>{props.submitIntermediaryStepState?.message}</ErrorLabel>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
