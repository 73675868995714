import React, { ReactElement, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { fetchRaisonSocialeOptions } from '../../../fetches/options.fetch';
import { useFetchOptionsOnFieldChange } from '../../../hooks/useFetchOptionsOnFieldChange.hook';
import { setFormWithFetchedEntreprise } from '../../../utils';
import InputText from '../InputText/InputText.component';
import InputTextAutoComplete from '../InputText/InputTextAutoComplete';

type EntrepriseInputsWithFetchProps = { form: UseFormReturn<any> };
export const EntrepriseInputsWithFetch = ({ form }: EntrepriseInputsWithFetchProps): ReactElement => {
  const {
    register,
    formState: { errors },
    watch,
    trigger,
    control,
    setValue
  } = form;

  const raisonSocialeOptions = useFetchOptionsOnFieldChange(
    watch,
    'entreprise.raisonSociale',
    fetchRaisonSocialeOptions,
    input => !!input && input.length > 2
  );

  // empty field siret when enCoursDeCreation is true
  const enCoursDeCreation = watch('enCoursDeCreation');
  useEffect(() => {
    if (enCoursDeCreation) {
      setValue('entreprise.siret', '');
      trigger('entreprise.siret');
    }
  }, [enCoursDeCreation]);

  return (
    <>
      {enCoursDeCreation && (
        <InputText
          label="Raison sociale"
          {...register('entreprise.raisonSociale')}
          name="entreprise.raisonSociale"
          errors={errors}
        />
      )}
      {!enCoursDeCreation && (
        <>
          <InputTextAutoComplete
            label="Raison sociale"
            name="entreprise.raisonSociale"
            control={control}
            options={raisonSocialeOptions}
            errors={errors}
            onSuggestionSelected={suggestion => {
              setFormWithFetchedEntreprise(suggestion?.meta?.numeroSIRET, setValue, trigger);
            }}
          />
          <InputText label="Numéro SIRET" {...register('entreprise.siret')} errors={errors} />
        </>
      )}
    </>
  );
};
