import { cloneDeepWith, isElement } from 'lodash';
import moment from 'moment';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { fetchDetailsEntreprise } from '../fetches/options.fetch';
import { FormStepConfig, FormStepConfigWithNext, MappingFunction, Option } from '../types';

export function getUrlParam(search: string, key: string): string | undefined {
  if (!search) return;
  const terms = search
    .slice(1)
    .split('&')
    .reduce<{ [key: string]: string }>((acc, curr) => {
      const [key, value] = curr.split('=');
      acc[key] = value;
      return acc;
    }, {});
  return terms[key];
}

export function getIn(pathValue: string, obj: Record<string, any>): any {
  if (!pathValue) return undefined;
  let pointer = obj;
  let subKey = null;
  for (const key of pathValue.split('.')) {
    if (!pointer) return undefined;
    if (key.includes('[')) {
      subKey = pointer[key.split('[')[0]];
      if (subKey) {
        pointer = subKey[key.split('[')[1].split(']')[0]];
        subKey = null;
        continue;
      }
    }
    pointer = pointer[key];
  }
  return pointer;
}

export function removeProperty<T>(property: keyof T, object: T): Omit<T, keyof T> {
  const { [property]: removed, ...filteredObject } = object; // eslint-disable-line
  return filteredObject;
}

export function convertEmptyStringsToNull(object: Record<string, any>): Record<string, any> {
  return cloneDeepWith(object, value => {
    if (isElement(value)) return value.cloneNode(true);
    if (value === '') return null;
  });
}

/**
 * Function to assign values for all properties of dest from source.
 * Mapping function can be passed to customize handling of values mapping for each property
 * Param stopOnArray can set to true if you want to assign array instead of loop on it.
 *
 * @param dest
 * @param source
 * @param mappingFunction
 * @param stopOnArray
 */
export const assignValuesFromSource = (
  dest: Record<string, any>,
  source: Record<string, any>,
  mappingFunction?: MappingFunction,
  stopOnArray = false
): Record<string, any> => {
  if (!source) return dest;
  for (const key of Object.keys(dest)) {
    if (!source[key]) {
      continue;
    }
    if (
      (typeof dest[key] === 'object' && !stopOnArray) ||
      (typeof dest[key] === 'object' && stopOnArray && !Array.isArray(dest[key]))
    ) {
      if (typeof source[key] !== 'object') {
        continue;
      }
      dest[key] = assignValuesFromSource(dest[key], source[key], mappingFunction);
    } else {
      dest[key] = mappingFunction ? mappingFunction(source[key], key) : source[key];
    }
  }
  return dest;
};

export function getAllStepsConfig(config: FormStepConfigWithNext[]): FormStepConfig[] {
  let forms: FormStepConfig[] = [];
  for (const step of config) {
    forms = [...forms, step];
    if (step.next) {
      for (const path in step.next.paths) {
        forms = [...forms, ...getAllStepsConfig(step.next.paths[path])];
      }
    }
  }
  return forms;
}

export async function setFormWithFetchedEntreprise(
  numeroSIRET: string,
  setValue: UseFormSetValue<any>,
  trigger: UseFormTrigger<any>
): Promise<any> {
  if (!numeroSIRET) return;
  const result = await fetchDetailsEntreprise(numeroSIRET);
  setValue('entreprise', {
    raisonSociale: result.raisonSociale ?? '',
    siret: result.numeroSIRET ?? '',
    codePostal: result.adresse.codePostal ?? '',
    ville: result.adresse.ville ?? '',
    pays: result.adresse.pays ?? '',
    voie: result.adresse.voie ?? '',
    complement: result.adresse.complement ?? '',
    creationDate: result.creationStructureDate ? moment(result.creationStructureDate).toDate() : null
  });
  trigger('entreprise');
}

type GetCodifLabelIExistFunction = (valueToFind: string, codifArray?: Option[]) => string;

export const getCodifLabelFromOptionsIfExist: GetCodifLabelIExistFunction = (valueToFind, codifArray) => {
  return codifArray?.find(codif => codif?.value === valueToFind)?.label ?? valueToFind;
};

export const flattenObj: any = (obj: any, conf: any) => {
  if (typeof obj !== 'function' && typeof obj !== 'object' && typeof obj !== 'undefined' && typeof obj !== 'symbol') {
    return [{ key: '', value: obj }];
  }
  const res = [];

  for (const key in obj) {
    if (conf?.nonFlattableKeys?.includes(key)) {
      res.push({ key, value: obj[key] });
    } else {
      const flattenChildren = flattenObj(obj[key], conf);
      for (const child of flattenChildren) {
        const childKey = child.key;

        let middlePoint = '';
        if (!Array.isArray(obj[key]) && childKey) {
          middlePoint = '.';
        }

        let parentKey = key;
        if (Array.isArray(obj)) {
          parentKey = '[' + key + ']';
        }

        res.push({
          key: parentKey + middlePoint + childKey,
          value: child.value
        });
      }
    }
  }
  return res;
};

// eslint-disable-next-line
export const createFormData = (values: any): FormData => {
  const fd = new FormData();
  flattenObj(values, { nonFlattableKeys: ['fileContent'] })?.forEach(({ key, value }: { key: string; value: any }) => {
    fd.append(key, value);
  });
  return fd;
};

export function mapByKey(array: any[], key: string): Record<string, unknown> {
  return array.reduce((map, item) => {
    map[item[key]] = item;
    return map;
  }, {});
}

export const getTypeConsentement = (type: string, cible: string | undefined): string => {
  const _cible = cible && cible !== 'empty' ? `:${cible}` : '';
  return `${type}${_cible}`;
};

export const formatLyaMontant = (montant: number, noMontantMessage = ''): string => {
  if (!montant) return noMontantMessage;

  const euroFRLocale = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  });
  return euroFRLocale.format(montant);
};

export const formatLyaDate = (date: string, noDateMessage = ''): string => {
  return date ? moment(date).format('DD/MM/YYYY') : noDateMessage;
};

export const lireFractionnementByPeriod = (fractionnementCode: string): string => {
  if (fractionnementCode === 'MENSUEL:FRACTIONNEMENT') {
    return 'mois';
  } else if (fractionnementCode === 'TRIMESTRIEL:FRACTIONNEMENT') {
    return 'trimestre';
  } else if (fractionnementCode === 'SEMESTRIEL:FRACTIONNEMENT') {
    return 'semestre';
  } else if (fractionnementCode === 'ANNUEL:FRACTIONNEMENT') {
    return 'an';
  }

  return '';
};

export const trimStringRecursively = (values: Record<string, any>, needToBeTrimKeys: string[]): void => {
  const keys = Object.keys(values);

  keys.forEach(key => {
    if (values[key] && typeof values[key] === 'object' && !Array.isArray(values[key])) {
      trimStringRecursively(values[key], needToBeTrimKeys);
    } else if (values[key] && needToBeTrimKeys.includes(key) && typeof values[key] === 'string') {
      values[key] = values[key].trim();
    }
  });
};
