import React, { ReactElement, useEffect, useState } from 'react';
import { FormProps, StepCodeAdhesion } from 'types';
import { useFetch } from '../../hooks/useFetch.hook';
import { fetchLibelleCodif } from '../../fetches/options.fetch';
import {
  formatLyaMontant,
  getCodifLabelFromOptionsIfExist,
  getUrlParam,
  lireFractionnementByPeriod
} from '../../utils';
import { ColoredText } from '../../components/ColoredText/ColoredText';
import ListDocsDevis from './ListDocsDevis';
import Separator from '../../components/Separator/Separator.component';
import './FormAdhesionStepSignature.scss';
import { ButtonCard } from '../../components/Button/ButtonCard';
import useCallApi from '../../hooks/useCallAPI.hook';
import { useLocation } from 'react-router';
import Loader from 'react-loader-spinner';

export default function FormDemandeAdhesionStepSignature(props: FormProps<any>): ReactElement {
  const { initialFormValues } = props;
  const [procedureSignature, setProcedureSignature] = useState(initialFormValues?.procedureSignature);
  const location = useLocation();
  const typologies = useFetch(fetchLibelleCodif);
  const caracteristiquesCodif = useFetch(() =>
    fetchLibelleCodif('CARACTERISTIQUE_DEVIS_' + initialFormValues?.typologieLabel.split(':')[0])
  );

  const handleNextClick = () => {
    props.goNextStep({ stepCode: StepCodeAdhesion.SIGNATURE });
    window.location = procedureSignature?.urlSignature;
  };
  const getProcedureSignatureFromAPI = useCallApi<any, any>(
    () =>
      fetch(
        `${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/${getUrlParam(
          location.search,
          'id'
        )}/signature/${getUrlParam(location.search, 'p')}`,
        {
          method: 'get',
          headers: { 'Content-Type': 'application/json' }
        }
      ),
    (data, successCallback) => {
      successCallback && successCallback(data);
    },
    err => {
      console.log(err);
    }
  );

  const handleSuccessGetProcedure = (setter: any) => (data: any) => {
    if (!data.procedureSignature || data.procedureSignature?.etatCode !== 'SIGNATURE_EN_ATTENTE:ETAT_SIGNATAIRE') {
      getProcedureSignatureFromAPI(
        { numeroProjet: initialFormValues?.numeroProjet },
        handleSuccessGetProcedure(setProcedureSignature)
      );
    } else {
      setter(data.procedureSignature);
    }
  };

  useEffect(() => {
    if (!procedureSignature) {
      getProcedureSignatureFromAPI(
        { numeroProjet: initialFormValues?.numeroProjet },
        handleSuccessGetProcedure(setProcedureSignature)
      );
    }
  }, []);

  const selectedQuote = initialFormValues?.proposedQuotes.find(
    (quote: any) => quote.numeroInterne === initialFormValues?.numeroDevisChoisi
  );
  if (!caracteristiquesCodif || !typologies || !selectedQuote) return <></>;

  return (
    <div className={'adhesion-step-signature form-adhesion'}>
      <div>
        <h2 className={'adhesion-step-signature__title'}>Récapitulatif et signature</h2>

        <div className={'adhesion-step-signature__card'}>
          <div className={'header bold-values'}>
            <div>
              <p className="form-font-large">
                <span>Votre assurance </span>
                <ColoredText
                  text={getCodifLabelFromOptionsIfExist(initialFormValues?.typologieLabel, typologies)}
                  color={'secondary'}
                />
              </p>
            </div>
          </div>
          <div className={'content'}>
            <div className={'cout'}>
              <div className={'cout__month'}>
                <span>
                  <b className="form-font-regular">Coût TTC </b>
                </span>
                <span>
                  <ColoredText
                    className={'cout__amount form-font-title'}
                    text={formatLyaMontant(selectedQuote.montant)}
                    color={'secondary'}
                  />
                  <span className="form-font-smaller months">
                    {lireFractionnementByPeriod(selectedQuote.fractionnementCode) !== '' &&
                      '/' + lireFractionnementByPeriod(selectedQuote.fractionnementCode)}
                  </span>
                </span>
              </div>
              {initialFormValues?.avecFrais === true && (
                <span>
                  <ColoredText
                    className="form-font-regular bold-values"
                    color={'secondary'}
                    text={formatLyaMontant(initialFormValues?.montantFrais)}
                  />
                  <span className="form-font-smaller"> de frais de dossier</span>
                </span>
              )}
            </div>
            <Separator />
            <div className={'detail'}>
              <span className={'detail__assureur form-font-smaller'}>
                <img src={selectedQuote.produit.assureur.urlLogo} width="35%" />
              </span>
            </div>
            <Separator />
            <div className={'docs'}>
              <ListDocsDevis documents={initialFormValues?.documentsInfos} quote={selectedQuote} />
            </div>
          </div>
        </div>

        <div className={'adhesion-step-signature__signButton'}>
          {!procedureSignature?.urlSignature && (
            <div className={'loading-link-signature'}>
              Récupération du lien de signature en cours . . .
              <Loader type="Rings" color="var(--color-primary)" height={25} width={25} />
            </div>
          )}
          {!!procedureSignature?.urlSignature &&
            procedureSignature?.etatCode !== 'SIGNATURE_EN_ATTENTE:ETAT_SIGNATAIRE' && (
              <div className={'loading-link-signature'}>
                Merci de patienter encore quelques instants . . .
                <Loader type="Rings" color="var(--color-primary)" height={25} width={25} />
              </div>
            )}
          {procedureSignature?.urlSignature &&
            procedureSignature?.etatCode === 'SIGNATURE_EN_ATTENTE:ETAT_SIGNATAIRE' && (
              <div className="next-button-container">
                <ButtonCard
                  className={'primary form-adhesion-next-button no-default-style form-font-regular'}
                  onClick={handleNextClick}
                >
                  J&apos;accède à la signature
                </ButtonCard>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
