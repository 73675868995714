import { FormProps } from '../../types';
import React, { ReactElement, useMemo } from 'react';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from '../../utils/Yup';
import InputText from '../../components/Input/InputText/InputText.component';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import FormMandatSepaFooter from './FormMandatSepaFooter.component';
import './FormMandatSepaVotreRIB.scss';
import { assignValuesFromSource } from 'utils';

interface FormValues {
  iban: string;
  bic: string;
}

const defaultState: FormValues = {
  iban: '',
  bic: ''
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  iban: Yup.string().iban().required(),
  bic: Yup.string().bic().required()
}).defined();

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormMandatSepaVotreRIB(props: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    setValue('iban', data.iban);
    setValue('bic', data.bic);
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formMandatSepaVotreRIB">
      <p className="formMandatSepaVotreRIB__title">Votre RIB</p>
      <p className="formMandatSepaVotreRIB__text">
        Indiquez ici les coordonnées bancaires du compte sur lequel vous souhaitez que Tutélaire prélève vos
        cotisations.
        <br />
        <b>
          Depuis le site, seul le paiement par prélèvement sur un compte bancaire ouvert auprès d’un établissement
          bancaire français est possible. Pour un compte bancaire étranger autorisé dans la zone SEPA merci de nous
          contacter .
        </b>
        <br />
        Vous trouverez ce numéro de compte sur votre RIB.
      </p>
      <InputText label="IBAN (International Bank Account Number)" {...register('iban')} errors={errors} />
      <InputText
        label="Code BIC"
        tooltip={'Veuillez saisir votre code BIC sans espaces'}
        errors={errors}
        preventNumber={true}
        {...register('bic')}
      />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>

      <FormMandatSepaFooter />
    </form>
  );
}
