import React, { FC } from 'react';
import { RoundedCard } from '../../../components/RoundedCard/RoundedCard';
import classNames from 'classnames';
import { formatLyaMontant } from 'utils';

interface CostRowProps {
  proposedQuotes: any;
  quoteMobileDisplay: number;
}

function getMontantDevis(quote: any) {
  if (quote.fractionnementCode === 'TRIMESTRIEL:FRACTIONNEMENT') return quote.montant / 3;
  if (quote.fractionnementCode === 'SEMESTRIEL:FRACTIONNEMENT') return quote.montant / 6;
  if (quote.fractionnementCode === 'SEMESTRIEL:FRACTIONNEMENT') return quote.montant / 12;

  return quote.montant;
}

const CostRow: FC<CostRowProps> = ({ proposedQuotes, quoteMobileDisplay }) => {
  return (
    <div className={'row-cost'}>
      <div className={'title'}>
        <h3 className={'form-font-large'}>Coût TTC</h3>
      </div>
      <div className={'content'}>
        {proposedQuotes.map((quote: any, index: number) => {
          return (
            <RoundedCard
              key={quote.numeroInterne}
              className={classNames(
                'tertiary',
                'card',
                'item',
                {
                  [`mobile-displayed__${index}`]: index === quoteMobileDisplay
                },
                {
                  [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                },
                `_${index}`
              )}
            >
              <b className="montant-label text-colored secondary form-font-title">
                {formatLyaMontant(getMontantDevis(quote))}
              </b>
            </RoundedCard>
          );
        })}
      </div>
    </div>
  );
};

export default CostRow;
