import { FormProps } from '../../types';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import parseSearch from '../../utils/parseSearch';
import { useGenerateProcedureSignature } from '../../hooks/useMandatSepa.hook';
import Yup from '../../utils/Yup';
import './FormMandatSepaSignature.scss';
import FormMandatSepaFooter from './FormMandatSepaFooter.component';
import FormConsent from 'components/FormConsent/FormConsent.component';
import { ReactComponent as SvgTime } from 'assets/time.svg';
import { assignValuesFromSource } from 'utils';

interface FormValues {
  consentement?: boolean;
}

const defaultState: FormValues = {
  consentement: false
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  consentement: Yup.boolean().isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.')
}).defined();

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormMandatSepaSignature(props: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const [urlSignature, setUrlSignature] = useState<string>('');

  const id = parseSearch(window.location.search, 'id');

  const handleNextClick = async (data: FormValues) => {
    window.open(urlSignature, '_blank');
    await props.goNextStep(data);
  };

  useEffect(() => {
    let si: NodeJS.Timeout;

    if (urlSignature === '') {
      useGenerateProcedureSignature({
        idFormulaire: id,
        numeroClient: props.previousFormValues.numeroClient
      })
        .then(url => {
          setUrlSignature(url);
        })
        .catch(err => {
          console.error(err);
          throw err;
        });
    }
    return () => clearInterval(si);
  }, [urlSignature]);

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formMandatSepaSignature">
      <div className="formMandatSepaSignature__justify">
        <p className="title formMandatSepaSignature__title">Il ne vous reste plus qu’à signer !</p>
        <p>
          Au clic sur le bouton <b>Accéder à la signature en ligne</b>, vous serez redirigé vers le site sécurisé de
          notre partenaire Yousign.
          <br />
          <br />
          Une fois tous les documents signés, vous recevrez un mail confirmant que nous avons bien reçu vos documents.
        </p>
        <div className="formMandatSepaSignature__paddingTop30">
          En signant ce formulaire de mandat, vous autorisez (A) la mutuelle TUTÉLAIRE à envoyer des instructions à
          votre banque pour débiter votre compte, et (B) votre banque à débiter votre compte conformément aux
          instructions de la mutuelle TUTÉLAIRE. Vous bénéficiez du droit d’être remboursé par votre banque selon les
          conditions décrites dans la convention que vous avez passée avec elle. Une demande de remboursement doit être
          présentée :
          <ul>
            <li>dans les 8 semaines suivant la date de débit de votre compte pour un prélèvement autorisé ;</li>
            <li> sans tarder et au plus tard dans les 13 mois en cas de prélèvement non autorisé</li>
          </ul>
          Note : vos droits concernant le présent mandat sont expliqués dans un document que vous pouvez obtenir auprès
          de votre banque.
        </div>
        <div className="formMandatSepaSignature__creancier">
          <p>
            <b>Créancier</b> :Tutélaire – 157 avenue de France – 75013 Paris{' '}
          </p>
          <p>
            Identifiant créancier SEPA : <b>FR88ZZZ386329</b>
          </p>
        </div>

        <FormConsent
          contenuConsentement={props.contenuConsentement}
          control={control}
          errors={errors}
          label={props.cocheConsentement}
          {...register('consentement')}
        />
        {urlSignature === '' ? (
          <div className="formMandatSepaSignature__waitingProcedureSignature">
            <SvgTime />
            <p>Merci de patienter encore quelques instants, votre procédure de signature sera bientôt disponible. </p>
          </div>
        ) : (
          <FormNextButton
            className="formMandatSepaSignature__submit"
            showCaptcha={props.showCaptcha}
            setCaptchaToken={props.setCaptchaToken}
            disabled={!isValid}
          >
            Accéder à la signature en ligne
          </FormNextButton>
        )}
        <FormMandatSepaFooter />
      </div>
    </form>
  );
}
