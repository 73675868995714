import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement } from 'react';
import FormConsent from '../../components/FormConsent/FormConsent.component';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import {
  ClientResiliationTutelaireProps,
  DocumentResiliationTutelaireProps,
  useSaveClientResiliationTutelaire,
  useSaveDocumentResiliationTutelaire
} from '../../hooks/useResiliationTutelaire.hook';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { FormProps } from '../../types';
import parseSearch from '../../utils/parseSearch';
import Yup from '../../utils/Yup';
import './FormResiliationConfirmation.scss';
import FormResiliationFooter from './FormResiliationFooter.component';

type FormValues = {
  consentement: boolean;
};

type PreviousFormValues = {
  documentsToUpload: DocumentResiliationTutelaireProps[];
} & ClientResiliationTutelaireProps;

const defaultState: FormValues = {
  consentement: false
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  consentement: Yup.boolean()
    .required()
    .isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.')
}).defined();

export default function FormResiliationConfirmation(props: FormProps<FormValues>): ReactElement {
  const [loading, setLoading] = React.useState(false);

  const id = parseSearch(window.location.search, 'id');
  if (!id) throw new Error("Le formulaire n'a pas été trouvé.");

  const { documentsToUpload, ...clientData }: PreviousFormValues = props.previousFormValues;
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultState // always reset to default state
  });

  const handleNextClick = async (data: FormValues) => {
    setLoading(true);
    const numeroClient = await useSaveClientResiliationTutelaire({ ...clientData, idFormulaire: id });
    for (const document of documentsToUpload) {
      await useSaveDocumentResiliationTutelaire({ ...document, numeroClient: numeroClient });
    }
    setLoading(false);
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formResiliationConfirmation">
      <p className="title">Confirmez votre demande</p>
      <p className="intro">
        Au clic sur le bouton <b>Envoyer ma demande de résiliation</b>, votre demande sera directement adressée à vos
        interlocuteurs Tutélaire.
        <p>Dès lors que votre demande de résiliation aura été traitée, vous recevrez un mail de confirmation. </p>
      </p>
      <FormResiliationFooter />

      <FormConsent
        contenuConsentement={''}
        control={control}
        errors={errors}
        label={props.cocheConsentement}
        name="consentement"
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken} loading={loading}>
        Envoyer ma demander de résiliation
      </FormNextButton>
    </form>
  );
}
