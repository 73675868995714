import React, { ReactElement, useState } from 'react';
import { AccumulateStateToSerie, FormProps, StepCodeAdhesion } from 'types';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useFetch } from '../../hooks/useFetch.hook';
import { fetchLibelleCodif } from '../../fetches/options.fetch';
import { getCodifLabelFromOptionsIfExist } from '../../utils';
import './FormDemandeAdhesionStepOffre.scss';

import { ColoredText } from '../../components/ColoredText/ColoredText';
import Yup from 'utils/Yup';
import _, { merge } from 'lodash';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutoSubmissionForm } from '../../components/AutoSubmissionForm/AutoSubmissionForm.component';
import ProductRow from './StepOffreRowCompare/ProductRow';
import AssureurRow from './StepOffreRowCompare/AssureurRow';
import GuaranteeRow from './StepOffreRowCompare/GuaranteeRow';
import DocumentationRow from './StepOffreRowCompare/DocumentationRow';
import CostRow from './StepOffreRowCompare/CostRow';
import ActionRow from './StepOffreRowCompare/ActionRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import CostFraisRow from './StepOffreRowCompare/CostFraisRow';

type FormValues = {
  numeroDevisChoisi: string;
  stepCode: string;
  assureurCode: string;
};

const defaultState: FormValues = {
  numeroDevisChoisi: '',
  stepCode: StepCodeAdhesion.OFFRE,
  assureurCode: ''
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  numeroDevisChoisi: Yup.string().required(),
  stepCode: Yup.string().required(),
  assureurCode: Yup.string().required()
}).defined();

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

const TAB_NUMBER = ['', 'une', 'deux', 'trois'];

const getInitialFormValues: any = (initialFormValues: any) => {
  const state: FormValues = defaultState;
  state.numeroDevisChoisi = initialFormValues.numeroDevisChoisi;
  state.assureurCode = initialFormValues.proposedQuotes.filter((quote: any) => {
    return quote.choisi === true;
  })[0]?.produit.assureur.code;
  return state;
};

export default function FormDemandeAdhesionStepOffre(props: FormProps<FormValues>): ReactElement {
  const { initialFormValues } = props;

  const initialValues = getInitialFormValues(initialFormValues);

  const {
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { isDirty }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues || defaultState // always reset to default state
  });

  const [quoteNumberToDisplay, setQuoteToDisplay] = useState(0);
  const typologies = useFetch(fetchLibelleCodif);
  let touched: number | null = null;
  if (!typologies) return <></>;

  return (
    <AutoSubmissionForm<FormValues>
      watch={watch}
      isDirty={isDirty}
      onSubmit={handleSubmit(values => {
        props.goNextStep(values, AccumulateStateToSeries);
      })}
    >
      <div className="form-adhesion">
        <h2 className={'adhesion-step-offre__title form-font-title'}>
          Nous avons sélectionné pour vous{' '}
          <ColoredText
            className={'secondary'}
            text={`${TAB_NUMBER[initialFormValues?.proposedQuotes?.length]}  ${
              initialFormValues?.proposedQuotes?.length > 1 ? 'offres' : 'offre'
            } ${getCodifLabelFromOptionsIfExist(initialFormValues?.typologieLabel, typologies)} :`}
          />{' '}
        </h2>
        <div
          className={'adhesion-step-offre__containerCompare'}
          onTouchEnd={e => {
            if (
              touched &&
              touched > e.changedTouches[0].clientX &&
              quoteNumberToDisplay < initialFormValues?.proposedQuotes.length - 1
            ) {
              setQuoteToDisplay(quoteNumberToDisplay + 1);
            } else if (touched && touched < e.changedTouches[0].clientX && quoteNumberToDisplay > 0) {
              setQuoteToDisplay(quoteNumberToDisplay - 1);
            }
          }}
          onTouchStart={e => (touched = e.touches[0].clientX)}
        >
          <div className={'header-compare-mobile'}>
            <button
              disabled={quoteNumberToDisplay === 0}
              className={'precButton no-default-style'}
              onClick={event => {
                event.preventDefault();
                setQuoteToDisplay(quoteNumberToDisplay - 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size={'2x'} />
            </button>
            <span className="product-number">
              {quoteNumberToDisplay + 1}/{initialFormValues?.proposedQuotes.length}
            </span>
            <button
              disabled={quoteNumberToDisplay === initialFormValues?.proposedQuotes.length - 1}
              className={'nextButton no-default-style'}
              onClick={event => {
                event.preventDefault();
                setQuoteToDisplay(quoteNumberToDisplay + 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} size={'2x'} />
            </button>
          </div>
          <ProductRow quoteMobileDisplay={quoteNumberToDisplay} proposedQuotes={initialFormValues?.proposedQuotes} />
          <AssureurRow quoteMobileDisplay={quoteNumberToDisplay} proposedQuotes={initialFormValues?.proposedQuotes} />
          <GuaranteeRow
            quoteMobileDisplay={quoteNumberToDisplay}
            proposedQuotes={initialFormValues?.proposedQuotes}
            typologieLabel={initialFormValues?.typologieLabel}
            guaranteeDocs={_.filter(initialFormValues?.documentsInfos, doc => {
              return doc.categories[0] === 'TABLEAU_COMPARATIF';
            })}
          />
          {Object.values(initialFormValues?.proposedQuotes || {}).find(
            (quote: any) => quote.urlDevis || quote.urlFicheProduit || quote.urlConditionsGenerales
          ) && (
            <DocumentationRow
              rowClassName={'__right-on-mobile'}
              quoteMobileDisplay={quoteNumberToDisplay}
              proposedQuotes={initialFormValues?.proposedQuotes}
              documents={initialFormValues?.documentsInfos}
            />
          )}

          {initialFormValues?.avecFrais === true && initialFormValues.montantFrais !== '0' && (
            <CostFraisRow
              proposedQuotes={initialFormValues?.proposedQuotes}
              montantFrais={initialFormValues?.montantFrais}
            />
          )}
          <CostRow quoteMobileDisplay={quoteNumberToDisplay} proposedQuotes={initialFormValues?.proposedQuotes} />

          <ActionRow
            quoteMobileDisplay={quoteNumberToDisplay}
            proposedQuotes={initialFormValues?.proposedQuotes}
            setValue={setValue}
            preconisation={initialFormValues?.preconisation}
            submitIntermediaryStepState={props.submitIntermediaryStepState}
            readOnly={props.readOnly}
            numeroDevisChoisiInitial={initialFormValues?.numeroDevisChoisi}
            numeroDevisChoisiValue={getValues('numeroDevisChoisi')}
            withLoader
          />
        </div>
        {props.readOnly && (
          <div className="next-button-container">
            <FormNextButton
              className="form-adhesion-next-button no-default-style form-font-regular"
              onClick={() => props.goNextStep(getValues(), AccumulateStateToSeries)}
              showCaptcha={props.showCaptcha}
              setCaptchaToken={props.setCaptchaToken}
            >
              Suivant
            </FormNextButton>
          </div>
        )}
      </div>
    </AutoSubmissionForm>
  );
}
