import React, { ReactElement } from 'react';
import './FormResiliationFooter.scss';

const FormResiliationFooter: () => ReactElement = () => (
  <div className="formResiliationFooter">
    <p className="formResiliationFooter__title">Protection des données personnelles</p>
    <p className="formResiliationFooter__text">
      Les informations recueillies dans le présent document, ou contenues dans les pièces demandées, ont pour finalités
      la gestion et l’exécution du contrat ainsi que la gestion du risque. Vous disposez notamment des droits d’accès,
      de rectification, d’opposition, de portabilité, d&apos;effacement et de limitation du traitement vous concernant.
      Pour l&apos;exercice de vos droits, adressez un courrier simple au responsable de traitement : Tutélaire, 157
      avenue de France, 75013 Paris ou un courriel à : donneespersonnelles@tutelaire.fr. Pour plus d’informations
      relatives à la gestion de vos données, vous pouvez à tout moment consulter notre politique de confidentialité sur
      le site internet www.tutelaire.fr ou la demander par courrier simple au responsable de traitement de Tutélaire.
    </p>
  </div>
);

export default FormResiliationFooter;
