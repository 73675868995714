import React, { FC } from 'react';
import { BarsLevel } from '../../../components/BarsLevel/BarsLevel';
import { ButtonCard } from '../../../components/Button/ButtonCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useFetch } from '../../../hooks/useFetch.hook';
import { fetchLibelleCodif } from '../../../fetches/options.fetch';
import classNames from 'classnames';
import _ from 'lodash';
import './GuaranteeRow.scss';

interface GuaranteeRowProps {
  proposedQuotes: any;
  typologieLabel: string;
  quoteMobileDisplay: number;
  guaranteeDocs: any;
}

const handleClickDownload = (e: any, url: string) => {
  e.preventDefault();
  window.open(url, '_blank');
};

const GuaranteeRow: FC<GuaranteeRowProps> = ({ proposedQuotes, typologieLabel, quoteMobileDisplay, guaranteeDocs }) => {
  const caracteristiquesCodif = useFetch(() =>
    fetchLibelleCodif('CARACTERISTIQUE_DEVIS_' + typologieLabel?.split(':')[0])
  );

  const getCustomDoc = () => {
    if (guaranteeDocs.length > 1) {
      return _.filter(guaranteeDocs, doc => {
        return !doc?.proprietes.AUTO_GENERE;
      });
    }
    return guaranteeDocs;
  };

  return (
    <div className={'row-guarantees'}>
      <div className={'title'}>
        <h3 className={'form-font-large'}>Garanties</h3>
        {caracteristiquesCodif?.map(codif => {
          return (
            <div key={codif.value} className={'codifContainer'}>
              <h5 className="form-font-small">{codif.label}</h5>
            </div>
          );
        })}
      </div>
      <div className={'row-guarantees content'}>
        {proposedQuotes.map((quote: any, index: number) => {
          return (
            <div
              key={quote.numeroInterne}
              className={classNames(
                `item`,
                {
                  [`mobile-displayed__${index}`]: index === quoteMobileDisplay
                },
                {
                  [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                },
                `_${index}`
              )}
            >
              <div className={'barsContainer'}>
                {caracteristiquesCodif?.map((codif, index) => {
                  return (
                    <div key={index} className={'row-guarantees rowBar'}>
                      <BarsLevel
                        key={quote.numeroInterne + codif.value}
                        numberItem={4}
                        numberSelected={quote['caracteristique' + (index + 1)]}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {guaranteeDocs.length > 0 && (
          <div className="button-details-garanties">
            <div className="button-details-garanties__wrapper">
              <ButtonCard
                className={`primary form-font-regular columns-${proposedQuotes.length}`}
                onClick={(e: any) => handleClickDownload(e, getCustomDoc()[0].url)}
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                Voir le détail des garanties
              </ButtonCard>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GuaranteeRow;
