import Yup from '../../../utils/Yup';
import { isNumeroSecuriteSocialeValide } from './NumeroSecu';

export type FormValuesPrevoyanceTNS = {
  numeroSecuriteSocial: string | undefined;
  codePostalNaissance: string | undefined;
  villeNaissance: string | undefined;
  paysNaissance: string | undefined;
  categorieSocioProfessionelleCode: string | undefined;
  categorieSocioProfessionelleLibelle: string | undefined;
  professionCode: string | undefined;
  professionLibelle: string | undefined;
  regimeFiscal: string | undefined;
  naf: string | undefined;
  dateClotureExercice: string | undefined;

  consentement: boolean | undefined;
  stepCode: string;
};
const defaultState: FormValuesPrevoyanceTNS = {
  numeroSecuriteSocial: '',
  codePostalNaissance: '',
  villeNaissance: '',
  paysNaissance: '',
  categorieSocioProfessionelleCode: '',
  categorieSocioProfessionelleLibelle: '',
  professionCode: '',
  professionLibelle: '',
  regimeFiscal: '',
  naf: '',
  dateClotureExercice: '',

  stepCode: 'INFOS',
  consentement: false
};

const validationSchema: Yup.SchemaOf<FormValuesPrevoyanceTNS> = Yup.object({
  numeroSecuriteSocial: Yup.string()
    .test(
      'numeroSecuriteSocial',
      "Le format du numéro de sécurité sociale n'est pas valide",
      isNumeroSecuriteSocialeValide
    )
    .required(),
  codePostalNaissance: Yup.string().required(),
  villeNaissance: Yup.string().required(),
  paysNaissance: Yup.string().required(),
  categorieSocioProfessionelleCode: Yup.string().required(),
  categorieSocioProfessionelleLibelle: Yup.string().required(),
  professionCode: Yup.string().required(),
  professionLibelle: Yup.string().required(),
  regimeFiscal: Yup.string().required(),
  naf: Yup.string().required(),
  dateClotureExercice: Yup.string().required(),
  stepCode: Yup.string().required(),
  consentement: Yup.boolean().isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.')
}).defined();

const getInitialFormValues: any = (initialFormValues: any) => {
  const state: FormValuesPrevoyanceTNS = defaultState;
  state.categorieSocioProfessionelleCode = initialFormValues?.categorieSocioProfessionelleCode ?? '';
  state.professionCode = initialFormValues?.professionCode ?? '';
  state.professionLibelle = initialFormValues?.professionLibelle ?? '';
  state.naf = initialFormValues?.naf ?? '';

  state.regimeFiscal = initialFormValues?.regimeFiscal ?? '';
  state.dateClotureExercice = initialFormValues?.dateClotureExercice ?? '';
  state.numeroSecuriteSocial = initialFormValues?.numeroSecuriteSocial ?? '';
  state.paysNaissance = initialFormValues?.paysNaissance ?? '';
  state.codePostalNaissance = initialFormValues?.codePostalNaissance ?? '';
  state.villeNaissance = initialFormValues?.villeNaissance ?? '';

  return state;
};

export const PrevoyanceTNS = {
  defaultState,
  validationSchema,
  getInitialFormValues
};
