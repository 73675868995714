import React, { ReactElement } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { FormValues } from '../FormMandatSepaTitulaireCompte.component';
import './NumeroAdherent.scss';
import InputText from 'components/Input/InputText/InputText.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

type NumeroAdherentType = {
  index: number;
  removeNumeroAdherent: (index: number) => void;
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
};

const NumeroAdherent = ({ index, removeNumeroAdherent, register, errors }: NumeroAdherentType): ReactElement => {
  return (
    <div className="numeroAdherent__solo">
      <InputText
        label={`Numéros d’adhérent - ${index + 1}`}
        {...register(`numerosAdherents.${index}.numeroAdherent`)}
        errors={errors}
        tooltip={
          index === 0
            ? 'Retrouvez votre Numéro d’adhérent en haut à gauche de votre espace adhérent, ou sur vos relevés de cotisation annuels. Attention : il existe un numéro d’adhérent' +
              ' par contrat.'
            : undefined
        }
        className="numeroAdherent__input"
      />
      {index !== 0 && (
        <button
          className="numeroAdherent__remove no-default-style"
          onClick={() => {
            removeNumeroAdherent(index);
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      )}
    </div>
  );
};

export default NumeroAdherent;
