import React, { FC } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import BlocConseilQuotes from '../../../components/BlocConseilQuotes/BlocConseilQuotes';
import { ErrorLabel } from '../../../components/Input/parts/ErrorLabel/ErrorLabel.component';
import { SerieSubmitStatus } from '../../../types';
import Loader from 'react-loader-spinner';
import './ActionRow.scss';

interface ActionRowProps {
  proposedQuotes: any;
  numeroDevisChoisiValue?: string;
  numeroDevisChoisiInitial?: string;
  setValue: any;
  readOnly?: boolean;
  preconisation: string;
  submitIntermediaryStepState: any;
  quoteMobileDisplay: number;
  withLoader: boolean;
}

const ActionRow: FC<ActionRowProps> = ({
  proposedQuotes,
  numeroDevisChoisiValue,
  numeroDevisChoisiInitial,
  setValue,
  readOnly,
  preconisation,
  submitIntermediaryStepState,
  quoteMobileDisplay,
  withLoader
}) => {
  const submittingStep = submitIntermediaryStepState?.status === SerieSubmitStatus.ONGOING;
  const submitInError = submitIntermediaryStepState?.status === SerieSubmitStatus.FAILED;
  const isFavoriteQuote = !!proposedQuotes.find((quote: any) => quote.favori === true);

  return (
    <div className={'row-actions emptyTitle'}>
      {withLoader && submittingStep && (
        <div className="loader">
          <div>
            <Loader type={'TailSpin'} color={'#03606D'} />
            <b>
              Chargement en cours
              <br />
              Merci de patienter.
            </b>
          </div>
        </div>
      )}
      <div className={'container-actions content'}>
        {proposedQuotes.map((quote: any, index: number) => {
          const quoteChoosing = numeroDevisChoisiValue === quote.numeroInterne;
          const isChoosenQuote = numeroDevisChoisiInitial === quote.numeroInterne;
          return (
            <div
              key={quote.numeroInterne}
              className={classNames(
                'buttons',
                'item',
                {
                  [`mobile-displayed__${index}`]: index === quoteMobileDisplay
                },
                {
                  [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                },
                `_${index}`
              )}
            >
              <button
                onClick={() => {
                  setValue('numeroDevisChoisi', quote.numeroInterne);
                  setValue('assureurCode', quote.produit.assureur.code);
                }}
                className={classNames(
                  'button-choice',
                  'tertiary',
                  { choosen: readOnly && isChoosenQuote },
                  { canceled: readOnly && !isChoosenQuote }
                )}
                disabled={readOnly || submittingStep}
              >
                {' '}
                <span className="form-font-regular">
                  {readOnly ? (isChoosenQuote ? 'Choisi' : 'Non choisi') : 'Choisir'}
                </span>
                {submittingStep && quoteChoosing && <Loader type="Rings" color="white" height={25} width={25} />}
                {isChoosenQuote && <FontAwesomeIcon icon={faCheckCircle} size={'lg'} />}
              </button>
              {quote.favori && preconisation && (
                <div className={'conseil__favori'}>
                  <BlocConseilQuotes conseil={preconisation} />
                </div>
              )}
            </div>
          );
        })}
        <br />
        {!submittingStep && submitInError && (
          <div className={'submit_error'}>
            <ErrorLabel>{submitIntermediaryStepState?.message}</ErrorLabel>
          </div>
        )}
        {!isFavoriteQuote && preconisation && (
          <BlocConseilQuotes className={classNames('conseil__general')} conseil={preconisation} />
        )}
      </div>
    </div>
  );
};

export default ActionRow;
