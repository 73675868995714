import classNames from 'classnames';
import React, { FC } from 'react';
import ListDocsDevis from '../ListDocsDevis';

interface DocumentationRowProps {
  proposedQuotes: any;
  quoteMobileDisplay: number;
  rowClassName?: string;
  documents: any;
}

const DocumentationRow: FC<DocumentationRowProps> = ({
  proposedQuotes,
  quoteMobileDisplay,
  rowClassName,
  documents
}) => {
  return (
    <div className={'row-documentation'}>
      <div className={'row-documentation title first-row'}>
        <h3 className={'form-font-large'}>Documentation</h3>
      </div>
      <div className={'row-documentation content'}>
        {proposedQuotes?.map((quote: any, index: number) => (
          <div
            key={quote.numeroInterne}
            className={classNames(
              'item',
              'item-container',
              { [`mobile-displayed__${index}`]: index === quoteMobileDisplay },
              {
                [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
              },
              `_${index}`
            )}
          >
            <ListDocsDevis documents={documents} quote={quote} className={rowClassName} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentationRow;
