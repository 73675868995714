import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement, useMemo } from 'react';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputSelect from '../../components/Input/InputSelect/InputSelect.component';
import InputText from '../../components/Input/InputText/InputText.component';
import { CIVILITES_OPTIONS } from '../../consts/consts';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { FormProps } from '../../types';
import { assignValuesFromSource } from '../../utils';
import Yup from '../../utils/Yup';
import './FormResiliationDemande.scss';
import FormResiliationFooter from './FormResiliationFooter.component';

export type FormValues = {
  civilite: string;
  prenom: string;
  nom: string;
  numeroAdherent: string;
  telephone: string;
  email: string;
  typeContrat: string;
};

const defaultState: FormValues = {
  civilite: '',
  prenom: '',
  nom: '',
  numeroAdherent: '',
  telephone: '',
  email: '',
  typeContrat: ''
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  civilite: Yup.string().required(),
  prenom: Yup.string().required(),
  nom: Yup.string().required(),
  numeroAdherent: Yup.string().required(),
  telephone: Yup.string()
    .required()
    .transform((val: string) => val.replace(/\s/g, ''))
    .max(255)
    .telephone(),
  email: Yup.string().max(255).email().required(),
  typeContrat: Yup.string().required()
}).defined();

export default function FormResiliationInformations(props: FormProps<FormValues>): ReactElement {
  function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
    return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
  }

  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = async (data: FormValues) => {
    props.goNextStep(data);
  };

  const typeContratOptions = [
    { label: 'Sâge autonomie', value: 'SAGE_AUTONOMIE' },
    {
      label: 'Paxivie',
      value: 'PAXIVIE'
    }
  ];

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formResiliationDemande">
      <p className="title">Vos informations</p>
      <InputRadioButtonInline
        labelField="Civilité"
        {...register('civilite')}
        options={CIVILITES_OPTIONS}
        errors={errors}
      />
      <div className="layout-field-container">
        <InputText label="Prénom" {...register('prenom')} errors={errors} preventNumber={true} />
        <InputText label="Nom" {...register('nom')} errors={errors} preventNumber={true} />
      </div>
      <div className="layout-field-container">
        <InputText
          label="Numéro d'ahérent"
          {...register('numeroAdherent')}
          errors={errors}
          tooltip="Retrouvez votre Numéro d’adhérent en haut à gauche de votre espace adhérent, ou sur vos relevés de cotisation annuels. Attention : il existe un numéro d’adhérent par garantie."
        />
      </div>
      <div className="layout-field-container">
        <InputText label="Téléphone portable" {...register('telephone')} errors={errors} />
        <InputText label="Adresse email" {...register('email')} errors={errors} />
      </div>
      <div className="layout-field-container">
        <InputSelect
          label="Type de contrat concerné"
          {...register('typeContrat')}
          control={control}
          errors={errors}
          options={typeContratOptions || []}
        />
      </div>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken} disabled={!isValid}>
        Suivant
      </FormNextButton>
      <FormResiliationFooter />
    </form>
  );
}
